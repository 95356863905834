@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.employee-stories-hero-container {
  width: 100%;
  padding: 0 !important;
  margin: 0;
  position: relative;

  &[data-narrow] {
    max-width: 100% !important; // Ensure the home hero container is not affected by narrow content container css
  }
}

.employee-stories-carousel {
  padding: 0;
}

.slide-outer {
  height: 100%;
  justify-content: center;
  transition: opacity 1s ease-in-out;
  contain: content;
}

.slide-inner {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.employee-stories-carousel-indicators {
  // the Carousel component has a more specific css that can’t be overridden
  margin-top: fn.spacing(-6);
  position: absolute;
  flex-direction: row;

  .div {
    opacity: 0.2;
    display: flex;
  }
}

.employee-stories-carousel-active-dot {
  opacity: 1 !important;
  background-color: var.$color-blue-500 !important;
}

.slide-content-container {
  box-sizing: border-box;
  flex: 1;
  height: 100%;
  max-width: var.$maximum-site-content-width-narrow;
  padding-left: fn.spacing(4);
  padding-right: fn.spacing(4);
  padding-top: fn.spacing(4);
  position: relative;
}

.slide-text {
  padding-bottom: 45vh; // try to ensure spacing below the text block to leave space for the background subject
}

.slide-text > *:not(:last-child) {
  margin-bottom: fn.spacing(1.5);
}

.employee-stories-carousel-controls {
  // The combination of Ascend/MUI component classes that get added to create the carousel
  // controls, (Button + IconButton size classes) creates a more specific selector that would
  // require 3 levels of specificity to override. Using important here instead.
  height: 40px !important;
  width: 40px !important;
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .employee-stories-carousel {
    min-height: 613px;
    padding-left: 0 !important; // Override specific CSS from Carousel
    padding-right: 0 !important; // Override specific CSS from Carousel
  }

  .employee-stories-carousel-indicators > div {
    margin-top: fn.spacing(-6);
  }

  .slide-content-container {
    padding-top: fn.spacing(14); // 112px
  }

  .slide-text {
    padding-left: fn.spacing(4);
    padding-bottom: 0;

    > *:not(:last-child) {
      margin-bottom: fn.spacing(2.5);
    }
  }
}
