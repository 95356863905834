@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.nav-articles-container {
  background-color: white;
  z-index: 2;
}

.nav-articles-container[data-sticky='true'] {
  position: sticky;
  top: 53px;
}

.nav-articles-section {
  text-align: center;
  padding: fn.spacing(2) fn.spacing(1);
  background: linear-gradient(180.32deg, #e3ebfe 0.28%, #f9f9fe 127.95%);
  justify-content: center;
}

.nav-articles-list {
  display: flex;
  flex-direction: column;
  gap: fn.spacing(1);
  justify-content: center;
  padding: fn.spacing(2) fn.spacing(1);
  border-bottom: 0.5px solid #10182817;
  background: var.$color-background-secondary;
  transition: all 0.5s ease-in;
  overflow: hidden;
}

.nav-articles-list[data-open='false'] {
  padding: 0;
  max-height: 0;
}

.nav-articles-item {
  border-radius: fn.spacing(1);
  padding: fn.spacing(0.5) fn.spacing(1.5);
  text-wrap: nowrap;
}

.nav-articles-item[data-active='true'] {
  background: var.$color-background-blue;
  color: var.$color-blue-400;
}

.nav-articles-item-cotainer {
  display: flex;
  justify-content: center;
}

.nav-articles-title {
  display: flex;
  flex-direction: row;
  gap: fn.spacing(1);
  justify-content: center;
}

.chevron-icon {
  color: var.$color-blue-300;
  cursor: pointer;
}

@media screen and (min-width: var.$breakpoint-lg) {
  .nav-articles-list {
    flex-direction: row;
    gap: fn.spacing(4);
    background: var.$color-neutral-0;
    max-height: 100px;
  }

  .nav-articles-container [data-sticky='true'] {
    top: 63px;
  }

  .nav-articles-section {
    padding: fn.spacing(4) fn.spacing(2);
  }
}
