@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  position: relative;
  overflow: hidden;
}

.section-inner {
  min-height: 540px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-good-app .section-inner {
  min-height: 617px;
}

.content-container {
  position: relative;
  height: 100%;
}

.copy {
  padding: fn.spacing(3) fn.spacing(3);

  > *:not(:last-child) {
    margin-bottom: fn.spacing(1.5);
  }
}

.buttons {
  height: 38px;
  margin-top: fn.spacing(4);
}

.button-apple {
  height: 38px;
  width: 118px;
  margin-right: fn.spacing(2);
}

.button-google {
  width: 134px;
  height: 38px;
}

.hero-image-grid-item {
  position: relative;
}

.hero-image-container {
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  width: 197px;
  height: 394px;
  margin-bottom: fn.spacing(3);
}

.section-good-app .hero-image-container {
  height: 392px;
  width: 197px;
  top: fn.spacing(0);
  margin-bottom: fn.spacing(0);

  img {
    object-position: top;
  }
}

.section-debt .hero-image-container {
  height: 392px;
  width: 100%;
  top: fn.spacing(0);
  margin-bottom: fn.spacing(0);
  display: flex;
  justify-content: center;

  img {
    object-position: top;
    height: 100%;
  }
}

.section-debt .hero-image-grid-item::before {
  content: '';
  position: absolute;
  width: 422.01px;
  height: 441.9px;
  top: 0;
  left: -20.2px;
  gap: 0;
  transform: rotate(-0.01deg);
  background: linear-gradient(
    180deg,
    rgb(25 56 219 / 20%) 14.68%,
    rgb(33 161 255 / 20%) 73.99%,
    rgb(254 206 47 / 20%) 98.44%
  );
  filter: blur(75px);
}

.app-buttons-container {
  height: 38px;
}

/* Tablet (large or greater) styles */
@media screen and (min-width: var.$breakpoint-sm) {
  .section-debt .hero-image-grid-item::before {
    left: 20%;
  }
}

/* Tablet (large or greater) styles */
@media screen and (min-width: var.$breakpoint-md) {
  .section-debt .hero-image-grid-item::before {
    left: 30%;
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .copy {
    padding: fn.spacing(3) 0;
  }

  .hero-image-grid-item {
    max-height: inherit;
  }

  .hero-image-container {
    position: absolute;
    height: 600px;
    width: 300px;
    margin-bottom: 0;
  }

  .section-good-app .hero-image-container {
    height: 676px;
    top: -48px;
    width: 340px;
    margin-bottom: fn.spacing(3);

    img {
      object-position: center center;
    }
  }

  .section-debt {
    background: var.$color-blue-25;
  }

  .section-debt .hero-image-container {
    width: 370px;
    height: 100%;
    margin-bottom: 0;

    img {
      object-position: center center;
    }
  }

  .section-debt .content-container {
    position: initial;
    height: initial;
    align-self: stretch;
  }

  .section-debt .section-inner {
    align-items: start;
  }

  .section-debt .copy {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .section-debt .hero-image-grid-item::before {
    content: '';
    position: absolute;
    width: 555.08px;
    height: 547.3px;
    top: 58.54px;
    left: 5%;
    gap: 0;
    opacity: 1;
    transform: rotate(-7.42deg);
    background: linear-gradient(
      180deg,
      rgb(25 56 219 / 20%) 14.68%,
      rgb(33 161 255 / 20%) 73.99%,
      rgb(254 206 47 / 20%) 98.44%
    );
    filter: blur(75px);
  }
}
