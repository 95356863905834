@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.flat-carousel-indicators {
  margin-top: fn.spacing(4);
}

.flat-carousel-wrapper {
  padding-top: 0;
}

.section {
  padding-top: fn.spacing(4);
  padding-bottom: fn.spacing(4);
  color: var.$color-neutral-0;
}

.image-container {
  // pretty unusual behavior the next image,
  // if there isn't text or setting something to a set a size
  // the image will just disappear from DOM on smaller viewports
  // it's probably something to do with the layout="responsive" and objectFit="contain" props
  min-width: 295px;
  max-width: 415px;
  max-height: 295px;
  aspect-ratio: 1 / 1;
}

.image-container img {
  border-radius: fn.spacing(1.5);
  width: 100%;
  height: 100%;
}

.card-text-grid {
  padding-left: fn.spacing(5);
  padding-right: fn.spacing(5);
  margin-bottom: fn.spacing(4);
  text-align: center;
}

.carousel-item-title {
  margin-bottom: fn.spacing(4);
}

.carousel-item-quote {
  margin-bottom: fn.spacing(3);
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .section {
    padding-top: fn.spacing(8);
    padding-bottom: fn.spacing(3);
  }

  .flat-carousel-wrapper {
    // TODO: Fix styles in the base Carousel.module.scss that are overly specific which makes
    // !important override here. See `.carousel-wrapper.standard` selector.
    padding-left: fn.spacing(12) !important;
    padding-right: fn.spacing(12) !important;
  }

  .card-text-grid {
    margin-bottom: fn.spacing(0);
    padding-left: 0;
    padding-right: 0;
    text-align: left;
  }

  .carousel-item-title {
    margin-bottom: fn.spacing(8);
  }

  .carousel-item-quote {
    margin-bottom: fn.spacing(8);
  }

  .image-container {
    max-height: 415px;
  }
}
