@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.author-container {
  display: flex;
  flex-direction: row;
  gap: fn.spacing(1);
}

.author-noimg {
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var.$color-neutral-150;
}

.card-link {
  color: var.$color-content-primary;
}

.author-link:hover,
.author-container a:hover {
  color: var.$color-blue-400;
}

.author-title {
  color: var.$color-content-secondary;
}
