@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.arrow {
  position: relative;
  transform: rotate(372deg);
  margin-left: fn.spacing(-22);
  margin-top: fn.spacing(2);
}

.handwritten {
  margin-top: fn.spacing(-3);
}

.indicators div {
  background-color: var.$color-neutral-150;
}

.section {
  padding: fn.spacing(6) 0;
}

.section-breakline {
  padding: fn.spacing(6) fn.spacing(5.75);
}

.grid-item {
  border: none;
}

.card-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 1rem;

  .value-prop-grid-separator {
    display: none;
  }
}

.card-grid-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 1rem;

  .value-prop-grid-separator {
    display: none;
  }
}

.card-grid.breakline {
  .value-prop-grid-card {
    align-self: center;
  }

  .value-prop-grid-separator {
    display: flex;
    min-width: 285px;
    min-height: 93px;
    align-items: center;

    div {
      width: 100%;
      border-bottom: 0.5px solid var.$color-neutral-500;
    }
  }
}

.card-grid-column.breakline {
  .value-prop-grid-card {
    align-self: center;
  }

  .value-prop-grid-separator {
    display: flex;
    min-width: 285px;
    min-height: 93px;
    align-items: center;

    div {
      width: 100%;
      border-bottom: 0.5px solid var.$color-neutral-500;
    }
  }
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: fn.spacing(6);
}

// These hard coded button sizes should be in line with styles from Ascend.
// TODO - Check with Design
.button {
  min-width: 293px;
  height: 47px;
}

.typography {
  min-width: 105px;
  text-align: center;
}

.title-divider-wrapper {
  > {
    padding: 0;
  }
}

.image-container {
  // pretty unusual behavior the next image,
  // if there isn't text or setting something to a set a size
  // the image will just disappear from DOM on smaller viewports
  // it's probably something to do with the layout="responsive" and objectFit="contain" props
  height: 100px;
  aspect-ratio: 1 / 1;
}

.carousel-item-title {
  padding-top: fn.spacing(2);

  & + .carousel-item-title {
    padding-top: 0;
  }
}

.carousel-item-quote {
  padding-top: fn.spacing(2);
  padding-right: fn.spacing(4);
}

.eyebrow {
  color: var.$color-content-primary;
}

div.active-indicator {
  background-color: var.$color-blue-500;
  opacity: 1;
}

.spacing-grid-item {
  padding-top: fn.spacing(0);
}

[data-no-margin='false'] {
  .flat-carousel-wrapper {
    padding: 0;
  }

  .value-card-grid-carousel {
    padding: 0 fn.spacing(2);
  }
}

/* Tablet (large or greater) styles */
@media screen and (min-width: var.$breakpoint-sm) {
  .section,
  .section-breakline {
    display: flex;
    justify-content: center;

    .container-grid {
      .title-divider-wrapper {
        h2 {
          padding: 0;
        }
      }
    }
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .arrow {
    margin-left: fn.spacing(-20);
  }

  .handwritten {
    margin-left: fn.spacing(0);
    margin-top: fn.spacing(-2.8);
  }

  .grid-item {
    border-right: 1px solid var.$color-neutral-500;
    padding-right: fn.spacing(2);
  }

  .section,
  .section-breakline {
    padding: fn.spacing(8) 0;

    .container-grid {
      max-width: 100%;
    }
  }

  .value-prop-title-divider {
    margin-bottom: fn.spacing(7);
  }

  .button-wrapper {
    margin-top: fn.spacing(8);
  }

  .card-grid {
    flex-direction: row;
    justify-content: center;

    .value-prop-grid-card {
      max-width: 305px;
      margin: auto;
    }

    .value-prop-grid-separator,
    &.breakline .value-prop-grid-separator {
      display: flex;
      justify-content: center;
      min-width: 93px;
      width: 93px;

      div {
        width: 0;
        height: 100%;
        border-left: 0.5px solid var.$color-neutral-500;
      }
    }
  }

  [data-no-margin='false'] {
    .value-card-grid-carousel {
      padding: 0;
    }
  }

  .spacing-grid-item {
    padding-top: fn.spacing(6);
  }
}
