@use 'styles/variables' as var;
@use 'styles/functions' as fn;

// Mobile styles, smaller than large breakpoint
.hero-section {
  margin: 0;
  padding: fn.spacing(5) fn.spacing(0);
}

.body-section {
  margin: 0;
  padding: 0 0 fn.spacing(4);
}

.body-text-section {
  scroll-margin-top: fn.spacing(12);
}

.vertical-menu {
  padding: 0;
  position: sticky;
  top: fn.spacing(12);
  margin-bottom: fn.spacing(4);

  .item {
    padding: 0;
    white-space: normal;
    min-height: auto;
    background-color: var.$color-neutral-0;

    a {
      border-left: 4px solid var.$color-border-opaque;
      color: var.$color-content-primary;
      text-decoration: none;
      width: 100%;
      padding: fn.spacing(2) fn.spacing(3);
    }
  }

  .item a[data-active='true'] {
    border-color: var.$color-content-accent;
    background-color: var.$color-neutral-0;
    z-index: 1;
    color: var.$color-content-accent;
  }
}

.table-custom p {
  margin-bottom: 0;
}

// Style changes for desktop styles, larger than large breakpoint
@media only screen and (min-width: var.$breakpoint-lg) {
  .hero-section {
    margin: 0;
    padding: fn.spacing(11) fn.spacing(14);
  }

  .body-section {
    margin: 0;
    padding-bottom: fn.spacing(8);
  }

  .item:hover a,
  .item:active a,
  .item:focus-within a,
  .item:focus a {
    border-color: var.$color-content-accent;
    background-color: var.$color-neutral-0;
    z-index: 1;
    color: var.$color-content-accent;
  }

  .vertical-menu {
    padding-right: fn.spacing(2);
  }
}
