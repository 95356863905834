@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.font-black {
  color: var.$color-content-primary;
}

.font-white {
  color: var.$color-neutral-0;
}

.font-blue {
  color: var.$color-blue-500;
}

.hero-carousel-wrapper {
  height: 485px;
  padding: 0 !important; // the Carousel component has a more specific css that can’t be overridden
}

.card-hero {
  background: var.$color-blue-50;
  display: flex;
  height: 100%;
  justify-content: center;
  transition: opacity 1s ease;

  &.card-hero-selected {
    transition: opacity 1s ease;
  }
}

.card-hero-block {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  border-radius: fn.spacing(1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-carousel-wrapper-indicators {
  // the Carousel component has a more specific css that can’t be overridden
  margin-top: fn.spacing(-7) !important;
  background-color: transparent !important;
  position: absolute !important;
}

.card-hero-content-bugline {
  padding-bottom: fn.spacing(1.5);
}

.card-hero-content {
  position: relative;
  height: 100%;
  max-width: 295px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .card-hero-content-link {
    margin-top: fn.spacing(6);
  }
}

.hero-carousel-wrapper-controls {
  height: 40px !important;
  width: 40px !important;
}

.hero-carousel-wrapper-controls.control-left {
  left: calc(50% - 500px) !important;
}

.shadow-none {
  text-shadow: none;
}

.shadow-min {
  text-shadow: 0 2px 9px rgb(16 24 40 / 9%), 0 2px 3px rgb(16 24 40 / 3%);
}

/* Tablet (large or greater) styles */
@media screen and (min-width: var.$breakpoint-md) {
  .card-hero-content {
    max-width: 545px;
  }

  .card-hero-content-info {
    max-width: 550px;
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .card-hero-content {
    max-width: 1007px;
    width: 1007px;
  }

  .hero-carousel-wrapper-indicators {
    margin-top: fn.spacing(-4.125) !important;
  }

  .title-right {
    width: fn.spacing(70);
    text-align: left;
    max-width: none;
    margin-left: fn.spacing(55);
  }

  .bugline-right {
    width: fn.spacing(70);
    text-align: left;
    max-width: none;
    margin-left: fn.spacing(55);
  }

  .title-left {
    width: 100%;
    text-align: left;
    max-width: none;
    margin-left: fn.spacing(15);
  }

  .bugline-left {
    width: 100%;
    text-align: left;
    max-width: none;
    margin-left: fn.spacing(15);
  }
}

/* Ultra (large or greater) styles */
@media screen and (min-width: var.$breakpoint-xxl) {
  .card-hero-content {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }

  .title-right {
    margin-left: fn.spacing(80);
  }

  .bugline-right {
    margin-left: fn.spacing(80);
  }

  .title-left {
    margin-left: fn.spacing(30);
  }

  .bugline-left {
    margin-left: fn.spacing(30);
  }
}
