@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.on-indicator {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  outline: none;
  border: none;
  margin: 0 fn.spacing(1);
  padding: 0;
  background-color: var.$color-blue-500;
  opacity: 1;
}

.off-indicator {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  outline: none;
  border: none;
  margin: 0 fn.spacing(1);
  padding: 0;
  background-color: var.$color-neutral-150;
  opacity: 0.2;
}

.dot-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1;
  position: relative;
}

.dot-controls-dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  outline: none;
  background-color: var.$color-neutral-0;
  opacity: 0.2;
  border: none;
  margin: 0 var.$spacing;
  padding: 0;

  [data-variant='onWhite'] & {
    background-color: var.$color-neutral-150;
    opacity: 1;
  }
}

.dot-controls-dot-active {
  opacity: 1;

  [data-variant='onWhite'] & {
    background-color: var.$color-blue-300;
    opacity: 1;
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .dot-controls-dot {
    width: 6px;
    height: 6px;
  }

  .dot-controls-dot-active {
    width: 6px;
    height: 6px;
  }
}
